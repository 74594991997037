// extracted by mini-css-extract-plugin
export var column = "ImageGallery__column__jQHVK";
export var galleryChevron = "ImageGallery__galleryChevron__LfsO_";
export var galleryClose = "ImageGallery__galleryClose__siyr5";
export var galleryContainer = "ImageGallery__galleryContainer__fj41d";
export var galleryCount = "ImageGallery__galleryCount__SBRcl";
export var galleryImageDescriptionContainer = "ImageGallery__galleryImageDescriptionContainer__ZbNwq";
export var galleryImageHidden = "ImageGallery__galleryImageHidden__Efi63";
export var galleryLeft = "ImageGallery__galleryLeft__GOn42";
export var galleryRight = "ImageGallery__galleryRight__WGTqw";
export var imageGalleryContainer = "ImageGallery__imageGalleryContainer__PZtrv";
export var row = "ImageGallery__row__YLJnL";